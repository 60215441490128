import './App.css';
import { BrowserRouter, Routes, Route, Router } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import Experiences from './pages/Experiences';
import Demography from './pages/Demography';
import About from './pages/About';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/experiences' element={<Experiences />} />
          <Route path='/demography' element={<Demography />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React from 'react'
import pic1 from '../assets/picture/gallery/pic-1.jpg'
import carousel1 from '../assets/picture/gallery/pic-13.jpg'
import carousel2 from '../assets/picture/gallery/pic-3.jpg'
import carousel3 from '../assets/picture/gallery/pic-4.jpg'
import carousel4 from '../assets/picture/gallery/pic-5.jpg'
import carousel5 from '../assets/picture/gallery/pic-10.jpg'
import loka1 from '../assets/picture/loka-1.png'
import kader_posyandu from '../assets/picture/kader-posyandu.png'
import posyandu_remaja from '../assets/picture/posyandu-remaja.png'
import map_bi from '../assets/picture/Bangun-Jaya-Map.jpg'
import user_icon from '../assets/icon/users.svg'
import area_icon from '../assets/icon/area.svg'
import warehouse_icon from '../assets/icon/warehouse.svg'
import map from '../assets/picture/Bangun-Jaya-new.png'

import { Carousel } from "flowbite-react";
import { motion } from 'framer-motion'
const profildesa = "Desa Bangun Jaya adalah sebuah desa yang terletak di Kecamatan Tanjung Raya, Kabupaten Mesuji, Provinsi Sumatera Selatan, Indonesia. Pendapatan Desa Bangun Jaya sebagian besar dialokasikan untuk bidang pembangunan desa. Bidang pembangunan infrastruktur menjadi prioritas di Desa Bangun Jaya dan Kabupaten Mesuji pada umumnya yang didominasi tanah liat sehingga sulit dijangkau ketika musim hujan. "


export default function
  () {
  return (
    <div>
      {/* Hero */}
      <div id='hero-banner' className='h-80 lg:h-screen flex items-center justify-center'>
        <motion.div
          whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className='flex flex-col items-center'>
          <h3 className='text-base-light tracking-wider lg:tracking-[0.48rem] text-2xl font-semibold font-main'>Welcome to</h3>
          <h1 className='text-base-light text-4xl md:text-5xl font-bold lg:text-8xl font-heading-hero'>Bangun Jaya</h1>
          <h2 className='text-base-light text-sm md:text-lg lg:text-xl font-normal font-main'>Mesuji Regency, Lampung City</h2>
        </motion.div>
      </div>


      {/* Profil Desa */}
      <section id='pattern-bg' className='lg:grid lg:grid-cols-2 gap-x-8 pt-40 px-8 items-center justify-items-center'>
        <motion.div
          whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          id='profile-bg' >
          <img src={map} className='lg:w-[30rem] object-contain' />
        </motion.div>
        <motion.article
          whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className='translate-y-[-20%] lg:translate-y-0 p-10 bg-profile-card rounded-[50px] shadow-profile-shadow'>
          <h1 className='font-main font-bold text-lg lg:text-4xl'>Bangun Jaya</h1>
          <p className='py-4 pe-4 font-main text-base lg:text-xl min-w-fit max-w-[70rem]'>
            {profildesa}
          </p>
        </motion.article>

      </section>



      {/* Gallery */}
      <section id='gallery-bg'>
        <motion.div
          whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className='pt-16 flex flex-col items-center gap-2'>
          <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>Gallery</h1>
          <p className='p-4 text-center font-main text-base lg:text-xl'>Choices of Bangun Jaya's Reminiscence</p>
        </motion.div>
        <motion.figure
          whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className='flex justify-center p-4'>
          <div className="w-full lg:w-[50rem] h-60 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel indicators={false}>
              <img src={carousel1} className='absolute block max-w-full h-auto -translate-x-1/2 -translate-y-[60%] top-1/2 left-1/2' />
              <img src={carousel2} className='absolute block max-w-full h-auto -translate-x-1/2 -translate-y-[60%] top-1/2 left-1/2' />
              <img src={carousel3} className='absolute block max-w-full h-auto -translate-x-1/2 -translate-y-[40%] top-1/2 left-1/2' />
              <img src={carousel4} className='absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' />
              <img src={carousel5} className='absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' />
            </Carousel>
          </div>
        </motion.figure>

        <motion.div
          whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className='flex justify-center pt-8'>
          <a href="/gallery" className='font-main font-semibold text-base-light'>
            <button className='bg-base-dark py-3 px-4 rounded-2xl'>
              View More
            </button>
          </a>
        </motion.div>

        {/* Articles */}
        <motion.section
          whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className='p-16 w-[100%]'>
          <header className=' flex flex-col items-center'>
            <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>Experiences</h1>
            <h2 className='p-4 text-center font-main text-base lg:text-xl'>Embark On Bangun Jaya's Activities</h2>
          </header >

          <div className='flex justify-center p-4'>
            <div className='flex w-[23rem] lg:w-[50rem] xl:w-[75rem] overflow-x-auto gap-12 pb-4'>
              <div className='bg-base-dark rounded-xl px-3 py-3 border-solid border-2 transition-all hover:transition-all hover:drop-shadow-lg hover:border-Portofolio-container-bg rounded-Porto-border'>
                <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/6695ed9a34777c024f641402/mahasiswa-kkn-dan-kader-posyandu-adakan-posyandu-balita-di-kabupaten-Mesuji" target='_blank'>
                  <figure className='w-max'>
                    <img src={kader_posyandu}
                      className='w-[21rem] h-[20rem] object-cover' />
                    <figcaption>
                      <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/6695ed9a34777c024f641402/mahasiswa-kkn-dan-kader-posyandu-adakan-posyandu-balita-di-kabupaten-Mesuji" target='_blank'>
                        <h3 className='text-base-light w-[21rem] font-semibold py-4 font-main text-lg text-ellipsis'>Mahasiswa KKN dan Kader Posyandu Adakan Posyandu Balita di Kabupaten Mesuji</h3>
                      </a>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div className='bg-base-dark rounded-xl px-3 py-3 border-solid border-2 transition-all hover:transition-all hover:drop-shadow-lg hover:border-Portofolio-container-bg rounded-Porto-border'>
                <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/668893de34777c21bb6248f2/dinas-kesehatan-kabupaten-mesuji-bersama-mahasiswa-kkn-ipb-adakan-posyandu-di-desa-bangun-jaya-kabupaten-mesuji" target='_blank'>
                  <figure className='w-max'>
                    <img src={posyandu_remaja}
                      className='w-[21rem] h-[20rem] object-cover' />
                    <figcaption>
                      <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/668893de34777c21bb6248f2/dinas-kesehatan-kabupaten-mesuji-bersama-mahasiswa-kkn-ipb-adakan-posyandu-di-desa-bangun-jaya-kabupaten-mesuji" target='_blank'>
                        <h3 className='text-base-light w-[21rem] font-semibold py-4 font-main text-lg text-ellipsis'>Dinas Kesehatan Kabupaten Mesuji bersama Mahasiswa KKN-T IPB Adakan Posyandu di Desa Bangun Jaya, Kabupaten Mesuji</h3>
                      </a>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div className='bg-base-dark rounded-xl px-3 py-3 border-solid border-2 transition-all hover:transition-all hover:drop-shadow-lg hover:border-Portofolio-container-bg rounded-Porto-border'>
                <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/6684eb4334777c0a3c3e3942/sah-mahasiswa-kkn-t-inovasi-ipb-siap-berkontribusi-untuk-desa-bangun-jaya-kabupaten-mesuji" target='_blank'>
                  <figure className='w-max'>
                    <img src={loka1}
                      className='text-base-light w-[21rem] h-[20rem] object-cover' />
                    <figcaption>
                      <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/6684eb4334777c0a3c3e3942/sah-mahasiswa-kkn-t-inovasi-ipb-siap-berkontribusi-untuk-desa-bangun-jaya-kabupaten-mesuji" target='_blank'>
                        <h3 className='text-base-light w-[21rem] font-semibold py-4 font-main text-lg text-ellipsis'>SAH! Mahasiswa KKN-T Inovasi IPB Siap Berkontribusi untuk Desa Bangun Jaya, Kabupaten Mesuji</h3>
                      </a>
                    </figcaption>
                  </figure>
                </a>
              </div>

            </div>
          </div>
          <div className='flex justify-center pt-8'>
            <a href="/experiences" className='font-main font-semibold text-base-light'>
              <button className='bg-base-dark py-3 px-4 rounded-2xl'>
                View More
              </button>
            </a>
          </div>
        </motion.section >

        {/* Demography */}
        <section className='p-16 pb-40'>
          <div className=' flex flex-col items-center'>
            <h1 className='font-main font-bold text-3xl lg:text-4xl'>Demography</h1>
            <p className='p-4 font-main text-center text-base lg:text-xl min-w-fit max-w-[90rem]'>Insight Of Bangun Jaya's Profile</p>
          </div>

          <div className='p-4 flex flex-col justify-center gap-5'>
            <div className='md:flex justify-center'>
              <img src={map_bi} className='bg-base-dark p-4 w-[30rem]  lg:w-[50rem] lg:h-[35rem] rounded-tr-3xl rounded-bl-3xl' />
            </div>
            <div className='flex flex-col md:flex-row justify-center gap-4 lg:gap-10'>

              {/* item */}
              <div className='p-4 bg-base-dark object-cover rounded-tr-3xl rounded-bl-3xl flex flex-col lg:flex-row  justify-center items-center gap-x-4'>
                <img src={user_icon} className='pt-4 w-16' />
                <figcaption className='p-4 lg:p-0'>
                  <h1 className='text-base-light text-center lg:text-left font-bold font-main text-2xl'>Population</h1>
                  <p className='pt-2 text-base-light text-center lg:text-left font-medium font-main text-4xl'>2761</p>
                </figcaption>
              </div>

              {/* item */}
              <div className='p-4 bg-base-dark object-cover rounded-tr-3xl rounded-bl-3xl flex flex-col lg:flex-row justify-center items-center gap-x-4'>
                <img src={area_icon} className='pt-4 w-16' />
                <figcaption className='p-4 lg:p-0'>
                  <h1 className='text-base-light text-center lg:text-left  font-bold font-main text-2xl'>Area</h1>
                  <p className='pt-2 text-base-light  text-center lg:text-left font-medium font-main text-4xl'>1460 Ha</p>
                </figcaption>
              </div>

              {/* item */}
              <div className='p-4 bg-base-dark object-cover rounded-tr-3xl rounded-bl-3xl flex flex-col lg:flex-row justify-center items-center gap-x-4'>
                <img src={warehouse_icon} className='pt-4 w-16' />
                <figcaption className='p-4 lg:p-0'>
                  <h1 className='text-base-light font-bold text-center lg:text-left  font-main text-2xl'>Comodity</h1>
                  <p className='pt-2 text-base-light font-medium  text-center lg:text-left font-main text-2xl lg:text-xl'>Palm, Rubber, Cassava</p>
                </figcaption>
              </div>
            </div>
          </div>
        </section>
      </section>

    </div>
  )
}

import React from 'react'
import pic1 from '../assets/picture/gallery/pic-1.jpg'
import pic2 from '../assets/picture/gallery/pic-2.jpg'
import pic3 from '../assets/picture/gallery/pic-3.jpg'
import pic4 from '../assets/picture/gallery/pic-4.jpg'
import pic5 from '../assets/picture/gallery/pic-5.jpg'
import pic6 from '../assets/picture/gallery/pic-6.jpg'
import pic7 from '../assets/picture/gallery/pic-7.jpg'
import pic8 from '../assets/picture/gallery/pic-8.jpg'
import pic9 from '../assets/picture/gallery/pic-9.jpg'
import pic10 from '../assets/picture/gallery/pic-10.jpg'
import pic11 from '../assets/picture/gallery/pic-11.jpg'
import pic12 from '../assets/picture/gallery/pic-12.jpg'
import pic13 from '../assets/picture/gallery/pic-13.jpg'
import pic14 from '../assets/picture/gallery/pic-14.jpg'
import pic15 from '../assets/picture/gallery/pic-15.jpg'
import pic16 from '../assets/picture/gallery/pic-16.jpg'
import { motion } from 'framer-motion'
export default function Gallery() {
    return (
        <div>
            {/* Hero */}
            <div id='gallery-banner' className='h-80 lg:h-screen flex items-center justify-center'>
                <motion.div
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='flex flex-col items-center'>
                    <h1 className='text-base-light text-7xl font-bold lg:text-9xl font-heading-hero'>Gallery</h1>
                    <h2 className='text-base-light text-sm md:text-lg lg:text-xl font-normal font-main'>A collection of Bangun Jaya’s magnificence</h2>
                </motion.div>
            </div>

            <motion.div
                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                transition={{ duration: 0.5 }}
                className='pt-16 flex flex-col items-center gap-2'>
                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>Bangun Jaya's Gallery</h1>
                <p className='p-4 text-center font-main text-base lg:text-xl'>Our choice of Bangun Jaya’s Reminiscense</p>
            </motion.div>


            {/* Gallery */}
            <section id='gallery-bg-page' className='p-4 flex flex-col items-center'>
                <div class="lg:max-w-[60rem] grid grid-cols-2 md:grid-cols-4 gap-4">
                    <motion.div class="grid gap-4">
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic1} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic2} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic3} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic12} alt="" />
                        </motion.div>
                    </motion.div>
                    <div class="grid gap-4">
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic4} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic5} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic6} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic11} alt="" />
                        </motion.div>
                    </div>
                    <div class="grid gap-4">
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic7} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic8} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic9} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic10} alt="" />
                        </motion.div>
                    </div>
                    <div class="grid gap-4">
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic13} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic14} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic15} alt="" />
                        </motion.div>
                        <motion.div
                            whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                            transition={{ duration: 0.5 }}>
                            <img class="h-auto max-w-full rounded-lg" src={pic16} alt="" />
                        </motion.div>
                    </div>
                </div>
            </section>
        </div>
    )
}

import React from 'react'
import { useState } from 'react'
import mobile_logo from "../assets/logo/logo-mobile.svg"
import desktop_logo from "../assets/logo/logo-desktop.svg"
import { Toast } from "flowbite-react";
import { motion } from "framer-motion";

export default function Navbar() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const toastVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
    };
    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div>
            <nav class="bg-base-dark">
                <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    {/* logo */}
                    <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={desktop_logo} class="w-32 hidden lg:inline-block" />
                        <img src={mobile_logo} class="h-8 lg:hidden" />
                    </a>

                    <script>

                    </script>

                    <button id='menu' onClick={toggleMenu} data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-base-light rounded-lg lg:hidden hover:bg-base-dark-active " aria-controls="navbar-default" aria-expanded={isExpanded}>
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>

                    <div class={`w-full lg:block lg:w-auto ${isExpanded ? '' : 'hidden'}`} id="navbar-default">
                        <ul class="font-medium gap-y-5 flex flex-col p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg bg-base-dark-active lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 lg:bg-base-dark dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700">
                            <a href="/">
                                <li className='py-1 px-3 text-lg lg:hover:border lg:hover:bg-base-dark-active lg:hover:rounded-lg'>
                                    <a href="/" class="font-main text-base-light ">Home</a>
                                </li>
                            </a>
                            <a href="/experiences">
                                <li className='py-1 px-3 text-lg lg:hover:border lg:hover:bg-base-dark-active lg:hover:rounded-lg'>
                                    <a href="/experiences" class="font-main text-base-light">Experiences</a>
                                </li>
                            </a>

                            {/* <li onClick={() => setShowToast((state) => !state)} className='cursor-pointer font-main text-base-light py-1 px-3 text-lg lg:hover:border lg:hover:bg-base-dark-active lg:hover:rounded-lg'>
                                Demography

                            </li> */}

                            <a href="/demography">
                                <li className='py-1 px-3 text-lg lg:hover:border lg:hover:bg-base-dark-active lg:hover:rounded-lg'>
                                    <a href="/demography" class="font-main text-base-light">Demography</a>
                                </li>
                            </a>

                            <a href="/gallery">
                                <li className='py-1 px-3 text-lg lg:hover:border lg:hover:bg-base-dark-active lg:hover:rounded-lg'>
                                    <a href="/gallery" class="font-main text-base-light">Gallery</a>
                                </li>
                            </a>
                            <a href="/about">
                                <li className='py-1 px-3 text-lg lg:hover:border lg:hover:bg-base-dark-active lg:hover:rounded-lg'>
                                    <a href="/about" class="font-main text-base-light">About Us</a>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
                {showToast && (
                    <motion.div
                        className="w-full bg-base-dark-active fixed top-2  transform -translate-x-1/2"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={toastVariants}
                        transition={{ duration: 0.3 }} // Adjust animation duration as needed
                    >
                        <Toast className="mt-14 bg-base-dark-active fixed top-2 left-1/2 transform -translate-x-1/2">
                            <div className="flex items-center justify-between w-full text-sm text-base-light font-main">
                                Page is still under development
                                <Toast.Toggle className="bg-base-dark-active" onDismiss={() => setShowToast(false)} />
                            </div>
                        </Toast>
                    </motion.div>
                )}

            </nav>

        </div>
    )
}

import React from 'react'
import { Tabs, Table } from 'flowbite-react'
import { motion } from 'framer-motion'
import map_bi from '../assets/picture/Bangun-Jaya-Map.jpg'
import rk1 from '../assets/picture/RK 1 (web).png'
import rk1_demo from '../assets/picture/RW1-demo.png'
import rk2 from '../assets/picture/RK 2 (web).png'
import rk2_demo from '../assets/picture/RW2-demo.png'
import rk3 from '../assets/picture/RK 3 (web).png'
import rk3_demo from '../assets/picture/RW3-demo.png'
import rk4 from '../assets/picture/RK 4 (web).png'
import rk4_demo from '../assets/picture/RW4-demo.png'
import rk5 from '../assets/picture/RK 5 (web).png'
import rk5_demo from '../assets/picture/RW5-demo.png'
import rk6 from '../assets/picture/RK 6 (web).png'
import rk6_demo from '../assets/picture/RW6-demo.png'
import rk7 from '../assets/picture/RK 7 (web).png'
import rk7_demo from '../assets/picture/RW7-demo.png'


export default function Demography() {
    return (
        <div>
            {/* Hero */}
            <div id='demography-banner' className='h-80 lg:h-screen flex items-center justify-center'>
                <motion.div
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='flex flex-col items-center'>
                    <h1 className='text-base-light text-4xl md:text-5xl font-bold lg:text-8xl font-heading-hero'>Demography</h1>
                </motion.div>
            </div>

            <section id='demo-bg-page'>
                <motion.div
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='pt-16 flex flex-col items-center gap-2'>
                    <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>Bangun Jaya's Administrative Map</h1>
                </motion.div>

                <motion.figure
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                    <img src={map_bi} className='w-[50rem]' />
                    <motion.article
                        whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                        transition={{ duration: 0.5 }}
                        className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                        <h1 className='font-main font-bold text-lg lg:text-4xl mb-8'>Bangun Jaya</h1>
                        <h3 className='font-main font-bold text-lg lg:text-xl mb-2'>Jumlah menurut agama / kepercayaan</h3>
                        <Table hoverable>
                            <Table.Head>
                                <Table.HeadCell className='font-main'>No</Table.HeadCell>
                                <Table.HeadCell className='font-main'>Agama yang dianut</Table.HeadCell>
                                <Table.HeadCell className='font-main'>Jumlah (Jiwa)</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className='font-main'>1</Table.Cell>
                                    <Table.Cell className='font-main'>Islam</Table.Cell>
                                    <Table.Cell className='font-main'>2.734</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className='font-main'>2</Table.Cell>
                                    <Table.Cell className='font-main'>Kristen</Table.Cell>
                                    <Table.Cell className='font-main'>17</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className='font-main'>3</Table.Cell>
                                    <Table.Cell className='font-main'>Budha</Table.Cell>
                                    <Table.Cell className='font-main'>-</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className='font-main'>4</Table.Cell>
                                    <Table.Cell className='font-main'>Hindu</Table.Cell>
                                    <Table.Cell className='font-main'>10</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className='font-main'>5</Table.Cell>
                                    <Table.Cell className='font-main'>Kristen Katolik</Table.Cell>
                                    <Table.Cell className='font-main'>-</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <h3 className='font-main font-bold text-lg lg:text-xl py-4'>Tingkat Pendidikan</h3>
                        <Table hoverable>
                            <Table.Head>
                                <Table.HeadCell className='font-main'>Prasekolah</Table.HeadCell>
                                <Table.HeadCell className='font-main'>TK</Table.HeadCell>
                                <Table.HeadCell className='font-main'>SD</Table.HeadCell>
                                <Table.HeadCell className='font-main'>SLTP</Table.HeadCell>
                                <Table.HeadCell className='font-main'>SLTA</Table.HeadCell>
                                <Table.HeadCell className='font-main'>Sarjana</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className='font-main'>86</Table.Cell>
                                    <Table.Cell className='font-main'>158</Table.Cell>
                                    <Table.Cell className='font-main'>293</Table.Cell>
                                    <Table.Cell className='font-main'>1.033</Table.Cell>
                                    <Table.Cell className='font-main'>1.084</Table.Cell>
                                    <Table.Cell className='font-main'>107</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </motion.article>
                </motion.figure>

                <motion.div
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='pt-16 flex flex-col items-center gap-2'>
                    <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>By Districts</h1>
                </motion.div>

                <div className='p-8'>
                    <Tabs aria-label="Pills" variant="pills">
                        <Tabs.Item active title="RK 1">
                            <motion.div
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='pt-16 flex flex-col items-center gap-2'>
                                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>RK 1</h1>
                            </motion.div>
                            <motion.figure
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                                <img src={rk1} className='w-[50rem]' />
                                <motion.article
                                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                                    <Table hoverable>
                                        <Table.Head>
                                            <Table.HeadCell className='font-main'>RT</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Jumlah KK</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>L</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>P</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Total</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>001</Table.Cell>
                                                <Table.Cell className='font-main'>53</Table.Cell>
                                                <Table.Cell className='font-main'>76</Table.Cell>
                                                <Table.Cell className='font-main'>81</Table.Cell>
                                                <Table.Cell className='font-main'>157</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>002</Table.Cell>
                                                <Table.Cell className='font-main'>46</Table.Cell>
                                                <Table.Cell className='font-main'>71</Table.Cell>
                                                <Table.Cell className='font-main'>77</Table.Cell>
                                                <Table.Cell className='font-main'>148</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>003</Table.Cell>
                                                <Table.Cell className='font-main'>44</Table.Cell>
                                                <Table.Cell className='font-main'>68</Table.Cell>
                                                <Table.Cell className='font-main'>75</Table.Cell>
                                                <Table.Cell className='font-main'>143</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <img src={rk1_demo} className='pt-8' />
                                </motion.article>
                            </motion.figure>

                        </Tabs.Item>
                        <Tabs.Item title="RK 2">
                            <motion.div
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='pt-16 flex flex-col items-center gap-2'>
                                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>RK 2</h1>
                            </motion.div>
                            <motion.figure
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                                <img src={rk2} className='w-[50rem]' />
                                <motion.article
                                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                                    <Table hoverable>
                                        <Table.Head>
                                            <Table.HeadCell className='font-main'>RT</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Jumlah KK</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>L</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>P</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Total</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>004</Table.Cell>
                                                <Table.Cell className='font-main'>49</Table.Cell>
                                                <Table.Cell className='font-main'>82</Table.Cell>
                                                <Table.Cell className='font-main'>74</Table.Cell>
                                                <Table.Cell className='font-main'>156</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>005</Table.Cell>
                                                <Table.Cell className='font-main'>38</Table.Cell>
                                                <Table.Cell className='font-main'>76</Table.Cell>
                                                <Table.Cell className='font-main'>69</Table.Cell>
                                                <Table.Cell className='font-main'>145</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>006</Table.Cell>
                                                <Table.Cell className='font-main'>32</Table.Cell>
                                                <Table.Cell className='font-main'>70</Table.Cell>
                                                <Table.Cell className='font-main'>60</Table.Cell>
                                                <Table.Cell className='font-main'>130</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <img src={rk2_demo} className='pt-8' />
                                </motion.article>
                            </motion.figure>

                        </Tabs.Item>
                        <Tabs.Item title="RK 3">
                            <motion.div
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='pt-16 flex flex-col items-center gap-2'>
                                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>RK 3</h1>
                            </motion.div>
                            <motion.figure
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                                <img src={rk3} className='w-[50rem]' />
                                <motion.article
                                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                                    <Table hoverable>
                                        <Table.Head>
                                            <Table.HeadCell className='font-main'>RT</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Jumlah KK</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>L</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>P</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Total</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>007</Table.Cell>
                                                <Table.Cell className='font-main'>51</Table.Cell>
                                                <Table.Cell className='font-main'>72</Table.Cell>
                                                <Table.Cell className='font-main'>74</Table.Cell>
                                                <Table.Cell className='font-main'>146</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>008</Table.Cell>
                                                <Table.Cell className='font-main'>47</Table.Cell>
                                                <Table.Cell className='font-main'>63</Table.Cell>
                                                <Table.Cell className='font-main'>71</Table.Cell>
                                                <Table.Cell className='font-main'>134</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>009</Table.Cell>
                                                <Table.Cell className='font-main'>41</Table.Cell>
                                                <Table.Cell className='font-main'>51</Table.Cell>
                                                <Table.Cell className='font-main'>73</Table.Cell>
                                                <Table.Cell className='font-main'>124</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>010</Table.Cell>
                                                <Table.Cell className='font-main'>40</Table.Cell>
                                                <Table.Cell className='font-main'>50</Table.Cell>
                                                <Table.Cell className='font-main'>60</Table.Cell>
                                                <Table.Cell className='font-main'>110</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <img src={rk3_demo} className='pt-8' />
                                </motion.article>
                            </motion.figure>
                        </Tabs.Item>
                        <Tabs.Item title="RK 4">
                            <motion.div
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='pt-16 flex flex-col items-center gap-2'>
                                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>RK 4</h1>
                            </motion.div>
                            <motion.figure
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                                <img src={rk4} className='w-[50rem]' />
                                <motion.article
                                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                                    <Table hoverable>
                                        <Table.Head>
                                            <Table.HeadCell className='font-main'>RT</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Jumlah KK</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>L</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>P</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Total</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>011</Table.Cell>
                                                <Table.Cell className='font-main'>52</Table.Cell>
                                                <Table.Cell className='font-main'>87</Table.Cell>
                                                <Table.Cell className='font-main'>52</Table.Cell>
                                                <Table.Cell className='font-main'>139</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>012</Table.Cell>
                                                <Table.Cell className='font-main'>45</Table.Cell>
                                                <Table.Cell className='font-main'>75</Table.Cell>
                                                <Table.Cell className='font-main'>51</Table.Cell>
                                                <Table.Cell className='font-main'>126</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>013</Table.Cell>
                                                <Table.Cell className='font-main'>41</Table.Cell>
                                                <Table.Cell className='font-main'>63</Table.Cell>
                                                <Table.Cell className='font-main'>48</Table.Cell>
                                                <Table.Cell className='font-main'>111</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <img src={rk4_demo} className='pt-8' />
                                </motion.article>
                            </motion.figure>

                        </Tabs.Item>
                        <Tabs.Item title="RK 5">
                            <motion.div
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='pt-16 flex flex-col items-center gap-2'>
                                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>RK 5</h1>
                            </motion.div>
                            <motion.figure
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                                <img src={rk5} className='w-[50rem]' />
                                <motion.article
                                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                                    <Table hoverable>
                                        <Table.Head>
                                            <Table.HeadCell className='font-main'>RT</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Jumlah KK</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>L</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>P</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Total</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>014</Table.Cell>
                                                <Table.Cell className='font-main'>49</Table.Cell>
                                                <Table.Cell className='font-main'>111</Table.Cell>
                                                <Table.Cell className='font-main'>95</Table.Cell>
                                                <Table.Cell className='font-main'>206</Table.Cell>
                                            </Table.Row>
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>015</Table.Cell>
                                                <Table.Cell className='font-main'>47</Table.Cell>
                                                <Table.Cell className='font-main'>103</Table.Cell>
                                                <Table.Cell className='font-main'>87</Table.Cell>
                                                <Table.Cell className='font-main'>190</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <img src={rk5_demo} className='pt-8' />
                                </motion.article>
                            </motion.figure>

                        </Tabs.Item>
                        <Tabs.Item title="RK 6">
                            <motion.div
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='pt-16 flex flex-col items-center gap-2'>
                                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>RK 6</h1>
                            </motion.div>
                            <motion.figure
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                                <img src={rk6} className='w-[50rem]' />
                                <motion.article
                                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                                    <Table hoverable>
                                        <Table.Head>
                                            <Table.HeadCell className='font-main'>RT</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Jumlah KK</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>L</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>P</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Total</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>016</Table.Cell>
                                                <Table.Cell className='font-main'>56</Table.Cell>
                                                <Table.Cell className='font-main'>107</Table.Cell>
                                                <Table.Cell className='font-main'>98</Table.Cell>
                                                <Table.Cell className='font-main'>205</Table.Cell>
                                            </Table.Row>

                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>017</Table.Cell>
                                                <Table.Cell className='font-main'>43</Table.Cell>
                                                <Table.Cell className='font-main'>97</Table.Cell>
                                                <Table.Cell className='font-main'>93</Table.Cell>
                                                <Table.Cell className='font-main'>190</Table.Cell>
                                            </Table.Row>


                                        </Table.Body>
                                    </Table>
                                    <img src={rk6_demo} className='pt-8' />
                                </motion.article>
                            </motion.figure>

                        </Tabs.Item>
                        <Tabs.Item title="RK 7">
                            <motion.div
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='pt-16 flex flex-col items-center gap-2'>
                                <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>RK 7</h1>
                            </motion.div>
                            <motion.figure
                                whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                className='p-8 flex flex-col translate-y-[-20%] lg:translate-y-0 xl:flex-row gap-x-4 justify-center items-center'>
                                <img src={rk7} className='w-[50rem]' />
                                <motion.article
                                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className='mt-4 xl:mt-0 p-10 w-fit h-fit bg-profile-card rounded-[50px] shadow-profile-shadow'>
                                    <Table hoverable>
                                        <Table.Head>
                                            <Table.HeadCell className='font-main'>RT</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Jumlah KK</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>L</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>P</Table.HeadCell>
                                            <Table.HeadCell className='font-main'>Total</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            <Table.Row className="bg-base-light dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell className='font-main'>018</Table.Cell>
                                                <Table.Cell className='font-main'>52</Table.Cell>
                                                <Table.Cell className='font-main'>101</Table.Cell>
                                                <Table.Cell className='font-main'>104</Table.Cell>
                                                <Table.Cell className='font-main'>201</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <img src={rk7_demo} className='pt-8' />
                                </motion.article>
                            </motion.figure>

                        </Tabs.Item>
                    </Tabs>


                </div>

            </section>


        </div>
    )
}

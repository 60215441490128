import React from 'react'
import arrow from '../assets/logo/arrow-icon.svg'
import { motion } from 'framer-motion'
import loka1 from '../assets/picture/loka-1.png'
import kader_posyandu from '../assets/picture/kader-posyandu.png'
import posyandu_remaja from '../assets/picture/posyandu-remaja.png'

export default function Experiences() {
    return (
        <div>
            {/* Hero */}
            <div
                id='experience-banner' className='h-80 lg:h-screen flex items-center justify-center'>
                <motion.div className='flex flex-col items-center'
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}>
                    <h1 className='text-base-light text-5xl font-bold lg:text-9xl font-heading-hero'>Experience</h1>
                    <h2 className='text-base-light text-sm md:text-lg lg:text-xl font-normal font-main'>Celebrate life, Community, and Nature</h2>
                </motion.div>
            </div>

            <section id='experience-page-bg'>
                <section className='flex flex-col items-center'>
                    <motion.div
                        whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                        transition={{ duration: 0.5 }}
                        className='pt-16 flex flex-col items-center gap-2'>
                        <h1 className='text-center font-main font-bold text-3xl lg:text-4xl'>Our Experience</h1>
                        <p className='p-4 text-center font-main text-base lg:text-xl'>Discover Activities In Bangun Jaya</p>
                    </motion.div>

                    <motion.article
                        whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                        transition={{ duration: 0.5 }}
                        className='py-8 flex gap-x-8 flex-wrap lg:flex-wrap justify-center'>
                        <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/6684eb4334777c0a3c3e3942/sah-mahasiswa-kkn-t-inovasi-ipb-siap-berkontribusi-untuk-desa-bangun-jaya-kabupaten-mesuji">
                            <div className='w-[12rem] md:w-[18rem] h-fit bg-base-dark rounded-2xl p-4 mb-4 '>
                                <div className='flex flex-col items-center'>
                                    <figure>
                                        <img src={loka1} className='mt-4 w-[20rem] h-[10rem] object-cover object-top rounded-tr-3xl rounded-bl-3xl' />
                                    </figure>
                                </div>
                                <div className='flex flex-col gap-y-6'>
                                    <div className='p-4'>
                                        <h1 className='font-main text-sm md:text-lg text-base-light font-semibold'>SAH! Mahasiswa KKN-T Inovasi IPB Siap Berkontribusi untuk Desa Bangun Jaya, Kabupaten Mesuji</h1>
                                    </div>
                                    <div className='flex justify-end gap-x-4'>
                                        <p className='font-main text-base-light'>Read More</p>
                                        <img src={arrow} />
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/668893de34777c21bb6248f2/dinas-kesehatan-kabupaten-mesuji-bersama-mahasiswa-kkn-ipb-adakan-posyandu-di-desa-bangun-jaya-kabupaten-mesuji">
                            <div className='w-[12rem] md:w-[18rem] h-fit bg-base-dark rounded-2xl p-4 mb-4 '>
                                <div className='flex flex-col items-center'>
                                    <figure>
                                        <img src={posyandu_remaja} className='mt-4 w-[20rem] h-[10rem] object-cover object-top rounded-tr-3xl rounded-bl-3xl' />
                                    </figure>
                                </div>
                                <div className='flex flex-col gap-y-6'>
                                    <div className='p-4'>
                                        <h1 className='font-main text-sm md:text-lg text-base-light font-semibold'>Dinas Kesehatan Kabupaten Mesuji bersama Mahasiswa KKN-T IPB Adakan Posyandu di Desa Bangun Jaya, Kabupaten Mesuji</h1>
                                    </div>
                                    <div className='flex justify-end gap-x-4'>
                                        <p className='font-main text-base-light'>Read More</p>
                                        <img src={arrow} />
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="https://www.kompasiana.com/262_muhammadnarazaky0096/6695ed9a34777c024f641402/mahasiswa-kkn-dan-kader-posyandu-adakan-posyandu-balita-di-kabupaten-Mesuji">
                            <div className='w-[12rem] md:w-[18rem] h-fit bg-base-dark rounded-2xl p-4 mb-4 '>
                                <div className='flex flex-col items-center'>
                                    <figure>
                                        <img src={kader_posyandu} className='mt-4 w-[20rem] h-[10rem] object-cover object-top rounded-tr-3xl rounded-bl-3xl' />
                                    </figure>
                                </div>
                                <div className='flex flex-col gap-y-6'>
                                    <div className='p-4'>
                                        <h1 className='font-main text-sm md:text-lg text-base-light font-semibold'>Mahasiswa KKN dan Kader Posyandu Adakan Posyandu Balita di Kabupaten Mesuji</h1>
                                    </div>
                                    <div className='flex justify-end gap-x-4'>
                                        <p className='font-main text-base-light'>Read More</p>
                                        <img src={arrow} />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </motion.article>
                </section>
            </section>
        </div>
    )
}

import React from 'react'
import Bisma from '../assets/picture/Bisma.jpg'
import DPL from '../assets/picture/DPL.jpg'
import Nara from '../assets/picture/Nara.jpg'
import Ras from '../assets/picture/Ras.jpg'
import Aqilla from '../assets/picture/Aqilla.jpg'
import Devi from '../assets/picture/Devi.jpg'
import Tyas from '../assets/picture/Tyas.jpg'
import Icad from '../assets/picture/Icad.jpg'
import Salma from '../assets/picture/Salma.jpg'
import { motion } from 'framer-motion'

export default function About() {
    return (
        <div>
            {/* Hero */}
            <div id='meisa-banner' className='h-80 lg:h-screen flex items-center justify-center bg-cover '>
                <motion.div
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='flex flex-col items-center'>
                    <h3 className='text-base-light text-sm md:text-lg lg:text-xl tracking-wider lg:tracking-[0.48rem] text-2xl font-semibold font-main'>Introducing</h3>
                    <h1 className='text-base-light text-7xl font-bold lg:text-9xl font-heading-hero'>Meisa</h1>
                    <h2 className='text-base-light text-sm md:text-lg lg:text-xl font-normal font-main'>Mesuji Satukan Asa</h2>
                </motion.div>
            </div>

            {/* About Meisa */}
            <section id='about-bg-page'>
                <motion.section
                    whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                    transition={{ duration: 0.5 }}
                    className='pt-24 px-6 lg:ps-[7rem]'>
                    <div className='flex flex-col gap-y-4 items-center lg:items-start  lg:w-[45rem]'>
                        <h1 className='text-5xl font-main font-bold'>About</h1>
                        <h3 className='text-3xl font-main font-bold'>Mesuji Satukan Asa</h3>
                        <p className='text-center lg:text-start p-4 rounded-xl text-lg font-main text-wrap text-base-light bg-base-dark-active'>Also known as <span className='font-main font-semibold'>Meisa</span>, is a team composed  of 8 people and 1 advisor to carry out KKNT from IPB University. KKNT IPB University 2024 Period 1 is being implemented in Mesuji Regency, covering two villages, namely Harapan Mukti Village and Bangun Jaya Village in Tanjung Raya District, with each village filled by eight students.  We want to share stories from our experiences which are poured into the form of a website. and hopefully it can be useful for visitors who wants to visit this beautiful village.</p>
                    </div>
                </motion.section>

                {/* core team */}
                <section className='p-8'>
                    <motion.div
                        whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                        transition={{ duration: 0.5 }}
                        className='pt-16 flex flex-col items-center gap-2'>
                        <h1 className='font-main font-bold text-3xl lg:text-4xl'>Meet Our Team</h1>
                        <p className='p-4 font-main text-base text-center lg:text-xl'>Meisa, Mesuji Satukan Asa Core Team</p>
                    </motion.div>
                    <figure className='pt-4 flex justify-center'>
                        <div className='flex flex-wrap justify-center max-w-[75rem] gap-4 md:gap-9'>
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Bisma} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={DPL} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-top rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Nara} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Ras} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Aqilla} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Devi} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Tyas} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Icad} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                            <motion.img whileInView={{ y: [30, 10, 0], opacity: [0, 0, 1] }}
                                transition={{ duration: 0.5 }}
                                src={Salma} className='w-[9rem] h-[9rem] md:w-72 md:h-64 lg:w-80 lg:h-72 object-cover object-center rounded-tr-3xl rounded-bl-3xl' />
                        </div>
                    </figure>
                </section>
            </section>
        </div>
    )
}

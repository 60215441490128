import React from 'react'
import footer_logo from "../assets/logo/logo-desktop.svg"
import insta_icon from "../assets/logo/Instagram-icon.svg"
import whatsapp_icon from "../assets/logo/whatsapp-icon.svg"
import mail_icon from "../assets/logo/mail-icon.svg"
import map_icon from "../assets/logo/map-icon.svg"
import call_icon from "../assets/logo/phone-icon.svg"
import { Toast } from "flowbite-react";
import { motion } from "framer-motion";
import { useState } from 'react'

export default function Footer() {
    const [showToast, setShowToast] = useState(false);
    const toastVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
    };
    return (
        <div className='py-5 bg-base-dark flex flex-col lg:flex-row lg:gap-10 lg:items-start items-center justify-evenly'>
            <div className='lg:flex lg:flex-col lg:items-center'>
                <img src={footer_logo} className='pt-8 px-4 min-w-36 w-[100%] max-w-96' />
                <p className='hidden lg:inline-block mt-4 text-center font-main font-thin text-base-light px-10 pb-7 min-w-52 opacity-50'>Copyrights @2024 Meisa. All Rights Reserved</p>
            </div>

            {/* features */}
            <section>
                <h1 className='mt-10 text-2xl font-main font-semibold text-base-light'>Discover</h1>
                <ul className='mt-4 text-center lg:text-left'>
                    <a href="/">
                        <li className='py-1 text-base font-semibold  font-main text-base-light'>Home</li>
                    </a>
                    <a href="/experiences">
                        <li className='py-1 text-base font-semibold font-main text-base-light'>Experiences</li>
                    </a>


                    {/* <li onClick={() => setShowToast((state) => !state)} className='cursor-pointer py-1 text-base font-semibold font-main text-base-light'>Demography</li> */}


                    <a href="/demography">
                        <li className='py-1 text-base font-semibold font-main text-base-light'>Demography</li>
                    </a>
                    <a href="/gallery">
                        <li className='py-1 text-base font-semibold font-main text-base-light'>Gallery</li>
                    </a>
                    <a href="/about">
                        <li className='py-1 text-base font-semibold font-main text-base-light'>About Us</li>
                    </a>
                </ul>
            </section>


            {/* Social Media */}
            <section className='flex flex-col justify-center lg:justify-start'>
                <h1 className='text-center lg:text-left mt-10 text-2xl font-main font-semibold text-base-light'>Follow Us</h1>
                <div className='mt-4 flex flex-col gap-4'>
                    <div className='flex'>
                        <a href="https://www.instagram.com/meisa.mesuji/" target='blank' className='flex'>
                            <img src={insta_icon} className='hidden w-6 lg:block' />
                            <p className='lg:mt-0  text-center font-main font-semibold text-base-light lg:px-5 px-10 min-w-52 lg:text-left'>Instagram</p>
                        </a>
                    </div>
                    <div className='flex'>
                        <a href="https://wa.me/6285863325554" target='blank' className='flex'>
                            <img src={whatsapp_icon} className='hidden w-6 lg:block' />
                            <p className='lg:mt-0  text-center font-main font-semibold text-base-light lg:px-5 px-10 min-w-52 lg:text-left'>Whatsapp</p>
                        </a>
                    </div>
                    <div className='flex'>
                        <a href="mailto:nazkyfanarazaky@apps.ipb.ac.id" target='blank' className='flex'>
                            <img src={mail_icon} className='hidden w-6 lg:block' />
                            <p className='lg:mt-0  text-center font-main font-semibold text-base-light lg:px-5 px-10 min-w-52 lg:text-left'>Email</p>
                        </a>
                    </div>
                </div>
            </section>



            {/* Contact */}
            <section className='max-w-96'>
                <h1 className='lg:text-left mt-10 text-2xl font-main font-semibold text-base-light text-center'>Our Location</h1>
                <div className='mt-4 lg:flex'>
                    <img src={map_icon} className='hidden lg:visible lg:inline-block' />
                    <p className='lg:mt-0 lg:text-left text-center font-main font-semibold text-base-light lg:px-5 px-10 min-w-52'>Bangun Jaya, Tanjung Raya, Kabupaten Mesuji, Kota Lampung 34697</p>
                </div>


                <div className='mt-4 lg:flex'>
                    <img src={call_icon} className='hidden lg:visible lg:inline-block' />
                    <article>
                        <p className='lg:mt-0  text-center font-main font-semibold text-base-light lg:px-3 px-10 min-w-52 lg:text-left'>085863325554</p>
                        <p className='text-center font-main font-semibold text-base-light lg:px-3 px-10 min-w-52 lg:text-left'>Muhammad Narazaky Pratama</p>
                    </article>
                </div>
                {showToast && (
                    <motion.div
                        className="w-full bg-base-dark-active fixed top-2  transform -translate-x-1/2"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={toastVariants}
                        transition={{ duration: 0.3 }} // Adjust animation duration as needed
                    >
                        <Toast className="mt-14 bg-base-dark-active fixed top-2 left-1/2 transform -translate-x-1/2">
                            <div className="flex items-center justify-between w-full text-sm text-base-light font-main">
                                Page is still under development
                                <Toast.Toggle className="bg-base-dark-active" onDismiss={() => setShowToast(false)} />
                            </div>
                        </Toast>
                    </motion.div>
                )}

            </section>

        </div>
    )
}
